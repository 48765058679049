<template>
  <div class="c-async-item mb-4">
    <ghost-loader
      v-if="loading"
      :height="height"
      :width="width"
      :inline="inline"
    />
    <slot v-else />
  </div>
</template>

<script>
import GhostLoader from "@/components/Atoms/GhostLoader.vue";

export default {
  name: "AsyncItem",
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    height: {
      type: Number,
      default: () => 20
    },
    width: {
      type: Number,
      default: () => 20
    },
    inline: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    GhostLoader
  }
}
</script>

<style scoped>

</style>
