<template>
  <div
    class="c-ghost-item"
    :class="inline ? 'c-ghost-item--inline': '' "
    :style="cCustomVars"
  ></div>
</template>

<script>
export default {
  name: "GhostLoader",
  props: {
    height: {
      type: Number,
      default: () => 20
    },
    width: {
      type: Number,
      default: () => null
    },
    inline: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    cCustomVars() {
      return {
        ...this.cHeight,
        ...this.cWidth
      }
    },
    cHeight() {
      return {
        '--cHeight': `${this.height}px`
      }
    },
    cWidth() {
      return {
        '--cWidth': `${this.width}px`
      }
    }
  }
}
</script>

<style scoped>
.c-ghost-item {
  display:block;
  min-height: 20px;
  background: #ececec;
  border-radius: 4px;
  height: var(--cHeight);
}
.c-ghost-item--inline {
  display: inline-block;
  width: var(--cWidth);
}
</style>
