<template>
  <card class="c-product-tag">
    <div slot="header">
      <h3>Dodawanie nowego tagu</h3>
    </div>
    <form
      @submit.prevent="handleFormSubmit"
      :class="cApiSending ? 'opacity-60' : ''"
    >
      <div class="row">
        <div class="col-md-6">
          <async-item :loading="loading" :height="45">
            <base-input
              required
              name="name"
              error="Test"
              label="Podaj nazwę tagu"
              v-model="cName"
            />
          </async-item>
        </div>
        <div class="col-md-6">
          <async-item :loading="loading" :height="45">
            <base-input label="Typ tagu" name="type" required v-model="cType">
              <el-select v-model="cType" >
                <el-option
                  v-for="type in tagOptions"
                  :key="type.key"
                  :value="type.key"
                  :label="type.label"
                />
              </el-select>
            </base-input>
          </async-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <async-item :loading="loading" :height="45">
            <base-input
              name="discount"
              required
              label="Rabat dla tagu"
              v-model="cDiscount"
            />
          </async-item>
        </div>
        <div class="col-md-6">
          <async-item :loading="loading" :height="45">
            <base-input
              label="Czy aktywny?"
            >
              <base-switch
                v-model="cActive"
                on-text="Tak"
                off-text="Nie"
              />
            </base-input>
          </async-item>
        </div>
      </div>
      <div class="pt-4 text-right">
        <async-item :loading="loading" :height="43" :width="86" inline>
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="formStatus === 'sending'"
          >Zapisz</button>
        </async-item>
      </div>
    </form>
  </card>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { Select, Option } from 'element-ui'
import AsyncItem from "@/components/AsyncItem.vue";
import ProductTagMixin from '@/mixins/views/Store/ProductTag/index';

const valueFactory = (field) => ({
  get() {
    return this.item[field]
  },
  set(value) {
    this.setItem({
      [field]: value
    })
  }
})

export default {
  name: "ProductTagForm",
  components: {
    AsyncItem,
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [ ProductTagMixin ],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('ProductTagViewModule', [ 'tagOptions', 'item' ]),
    cName: valueFactory('name'),
    cDiscount: valueFactory('discount'),
    cActive: valueFactory('active'),
    cType: valueFactory('type')
  },
  methods: {
    ...mapMutations('ProductTagViewModule', ['setTagType', 'setItem']),
    ...mapActions('ProductTagViewModule', ['handlePostItem']),
    async handleFormSubmit() {
      const payload = {
        token: this.$store.state.Auth.token,
        data: {
          name: this.cName,
          type: this.cType,
          active: this.cActive,
          discount: this.cDiscount
        },
        ...( this.item.id ? { id: this.item.id } : {})
      }
      console.log(payload)
      this.$emit('submit', payload )
    }
  }
}
</script>

<style scoped>
.opacity-60 {
  opacity: 0.6;
}
</style>
