<template>
  <div>
    <Spinner v-show="cApiSending" />
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>
    <div class="container-fluid mt--6">
      <div
        v-show="cPromiseResolved"
        class="alert"
        :class="[cAlertStatus]"
      >
        {{ cAlertMessage }}
      </div>
      <div class="row">
        <div class="col-md-6">
          <ProductTagForm @submit="handleSubmit"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import ProductTagForm from "@/components/ProductTag/ProductTagForm.vue";
import Spinner from "@/components/Atoms/Spinner.vue";
import ProductTagMixin from '@/mixins/views/Store/ProductTag'

export default {
  name: 'ProductTagView',
  components: {
    ProductTagForm,
    BreadcrumbHeader,
    Spinner
  },
  mixins: [ ProductTagMixin ],
  computed: {
    breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Tagi', link: {name: 'product-tag-list'}},
        {title: 'Nowy tag'}
      ];
    }
  },
  methods: {
    async handleSubmit(payload) {
      if ( payload.data.id ) {

      } else {
        console.log(payload)
        await this.handlePostItem(payload)
      }
    }
  }
};
</script>
